import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import RoleTemplate from '../../solutionsTemplate/roleTemplate';

const GeneralContractor = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiSolutionsByRole(
        filter: { ONLY_FOR_DEV_pageId: { eq: "generalContractor" } }
      ) {
        nodes {
          id
          PageIcon {
            url
          }
          PageLabel
          HeroMainBannerHeader
          HeroMainBannerDescription
          HeroMainBannerCTAButtonLink
          HeroMainBannerCTAButtonLabel
          System {
            id
            TabLabel
            SystemName
            SystemCTAButtonLabel
            SystemCTAButtonLink
            SystemImage {
              url
              alternativeText
            }
            SystemBenefit {
              id
              header
              description
            }
          }
        }
      }
    }
  `);

  return <RoleTemplate data={data} pageName="generalContractor" />;
};

export default GeneralContractor;
